import { Item, ItemRarity, ItemType } from "@/types/ItemTypes";

export const characters: { [key: string]: Item } = {
  ALBEDO: {
    name: "Albedo",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  ALHAITHAM: {
    name: "Alhaitham",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  ARLECCHINO: {
    name: "Arlecchino",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  AYAKA: {
    name: "Ayaka",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  AYATO: {
    name: "Ayato",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  BAIZHU: {
    name: "Baizhu",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  BARBARA: {
    name: "Barbara",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  BEIDOU: {
    name: "Beidou",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  BENNETT: {
    name: "Bennett",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  CANDACE: {
    name: "Candace",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  CHEVREUSE: {
    name: "Chevreuse",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  CHILDE: {
    name: "Childe",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  CHIORI: {
    name: "Chiori",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  CHONGYUN: {
    name: "Chongyun",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  CLORINDE: {
    name: "Clorinde",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  COLLEI: {
    name: "Collei",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  CYNO: {
    name: "Cyno",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  DIONA: {
    name: "Diona",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  DORI: {
    name: "Dori",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  EULA: {
    name: "Eula",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  FARUZAN: {
    name: "Faruzan",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  FISCHL: {
    name: "Fischl",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  FREMINET: {
    name: "Freminet",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  FURINA: {
    name: "Furina",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  GAMING: {
    name: "Gaming",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  GANYU: {
    name: "Ganyu",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  GOROU: {
    name: "Gorou",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  HEIZOU: {
    name: "Heizou",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  HU_TAO: {
    name: "Hu Tao",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  ITTO: {
    name: "Itto",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  KAVEH: {
    name: "Kaveh",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  KAZUHA: {
    name: "Kazuha",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  KIRARA: {
    name: "Kirara",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  KLEE: {
    name: "Klee",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  KOKOMI: {
    name: "Kokomi",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  KUJOU_SARA: {
    name: "Kujou Sara",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  KUKI_SHINOBU: {
    name: "Kuki Shinobu",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  LAYLA: {
    name: "Layla",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  LYNETTE: {
    name: "Lynette",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  LYNEY: {
    name: "Lyney",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  MIKA: {
    name: "Mika",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  NAHIDA: {
    name: "Nahida",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  NAVIA: {
    name: "Navia",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  NEUVILLETTE: {
    name: "Neuvillette",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  NILOU: {
    name: "Nilou",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  NINGGUANG: {
    name: "Ningguang",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  NOELLE: {
    name: "Noelle",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  RAIDEN: {
    name: "Raiden",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  RAZOR: {
    name: "Razor",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  ROSARIA: {
    name: "Rosaria",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  SAYU: {
    name: "Sayu",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  SCARAMOUCHE: {
    name: "Scaramouche",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  SETHOS: {
    name: "Sethos",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  SHENHE: {
    name: "Shenhe",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  SIGEWINNE: {
    name: "Sigewinne",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  SUCROSE: {
    name: "Sucrose",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  THOMA: {
    name: "Thoma",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  VENTI: {
    name: "Venti",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  WRIOTHESLEY: {
    name: "Wriothesley",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  XIANGLING: {
    name: "Xiangling",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  XIANYUN: {
    name: "Xianyun",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  XIAO: {
    name: "Xiao",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  XINGQIU: {
    name: "Xingqiu",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  XINYAN: {
    name: "Xinyan",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  YAE_MIKO: {
    name: "Yae Miko",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  YANFEI: {
    name: "Yanfei",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  YAOYAO: {
    name: "Yaoyao",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  YELAN: {
    name: "Yelan",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  YOIMIYA: {
    name: "Yoimiya",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
  YUN_JIN: {
    name: "Yun Jin",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Character,
  },
  ZHONGLI: {
    name: "Zhongli",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Character,
  },
};
