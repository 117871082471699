
import { defineComponent } from "vue";
import { Banner, banners } from "@/data/banners";
import { ItemType } from "@/types/ItemTypes";
import { bannerHasItem } from "@/services/BannerAnalyzer";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import { Bar } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);

export default defineComponent({
  name: "BannerItemTimeline",
  components: {
    Bar,
  },
  props: ["item"],
  computed: {
    banners(): Banner[] {
      return this.item.itemType === ItemType.Character
        ? banners.characterBanners
        : banners.weaponBanners;
    },
    chartData() {
      const labels = this.banners.map((banner) => banner.number);
      const data = this.banners.map(() => 1);
      const backgroundColors = this.banners.map((banner) => {
        return bannerHasItem(this.item, banner)
          ? "#cff09e"
          : "rgb(155,155,155)";
      });
      return {
        labels,
        datasets: [
          {
            data,
            backgroundColor: backgroundColors,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              color: "rgb(128, 130, 135)",
            },
            ticks: {
              display: false,
            },
          },
          y: {
            display: false,
          },
        },
      };
    },
  },
});
