import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db9fbe08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banners-since-item-bar-container" }
const _hoisted_2 = ["src", "title", "alt"]
const _hoisted_3 = { class: "item-bar-container" }
const _hoisted_4 = { class: "item-banners-since-label" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannersSinceItemMoreInfo = _resolveComponent("BannersSinceItemMoreInfo")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(`banners-since-item-container ${
      _ctx.isExpanded ? 'secondary-light-background' : ''
    }`),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleItemClick && _ctx.handleItemClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "item-image",
        src: _ctx.imageName,
        title: _ctx.analyzedItem.item.name,
        alt: _ctx.analyzedItem.item.name
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "item-bar",
          style: _normalizeStyle(`width: ${_ctx.barWidth}%`)
        }, null, 4),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.analyzedItem.bannersSince), 1)
      ]),
      _createElementVNode("img", {
        class: "item-banners-since-chevron",
        src: _ctx.chevronIcon
      }, null, 8, _hoisted_5)
    ]),
    (_ctx.isExpanded)
      ? (_openBlock(), _createBlock(_component_BannersSinceItemMoreInfo, {
          key: 0,
          analyzedItem: _ctx.analyzedItem
        }, null, 8, ["analyzedItem"]))
      : _createCommentVNode("", true)
  ], 2))
}