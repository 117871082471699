
import { defineComponent } from "vue";
import { ItemRarity } from "@/types/ItemTypes";

export default defineComponent({
  name: "RarityStars",
  props: ["rarity"],
  computed: {
    numStars(): number {
      return this.rarity === ItemRarity.FourStar ? 4 : 5;
    },
  },
});
