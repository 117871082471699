
import { defineComponent } from "vue";
import { Reliability, upcomingBanner } from "@/data/upcoming-banner";
import { Item } from "@/types/ItemTypes";
import ItemCard from "@/components/ItemCard.vue";

export default defineComponent({
  name: "UpcomingBannerContainer.vue",
  components: {
    ItemCard,
  },
  computed: {
    isSpeculation(): boolean {
      return upcomingBanner.reliability === Reliability.SPECULATION;
    },
    showWeapons(): boolean {
      return upcomingBanner.weapons.length > 0;
    },
    showCharacters(): boolean {
      return upcomingBanner.characters.length > 0;
    },
    weapons(): Item[] {
      return upcomingBanner.weapons;
    },
    characters(): Item[] {
      return upcomingBanner.characters;
    },
    headerText(): string {
      const reliabilityText =
        upcomingBanner.reliability === Reliability.SPECULATION
          ? "(Speculation)"
          : "";
      return `Upcoming Banner ${reliabilityText}`;
    },
  },
});
