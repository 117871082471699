import { characters } from "@/data/characters";
import { weapons } from "@/data/weapons";

export enum Reliability {
  SPECULATION,
  CONFIRMED,
}

export const upcomingBanner = {
  reliability: Reliability.CONFIRMED,
  characters: [characters.NAVIA, characters.NILOU],
  weapons: [weapons.VERDICT, weapons.KEY_OF_KHAJ_NISUT],
};
