import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-711b8d73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-card-container" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RarityStars = _resolveComponent("RarityStars")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.imageName,
      alt: _ctx.item.name
    }, null, 8, _hoisted_2),
    _createElementVNode("p", null, _toDisplayString(_ctx.item.name), 1),
    _createVNode(_component_RarityStars, {
      rarity: _ctx.item.rarity
    }, null, 8, ["rarity"])
  ]))
}