
import { defineComponent } from "vue";
import { ItemType, ItemRarity } from "@/types/ItemTypes";
import BannersSinceItem from "@/components/BannersSinceItem.vue";
import {
  analyzedCharacters,
  AnalyzedItem,
  analyzedWeapons,
} from "@/services/BannerAnalyzer";

export default defineComponent({
  name: "BannersSinceContainer",
  components: {
    BannersSinceItem,
  },
  data() {
    return {
      itemTypes: ItemType,
      itemRarities: ItemRarity,
      selectedItemType: ItemType.Character,
      selectedItemRarity: ItemRarity.FourStar,
      showStandard: false,
    };
  },
  computed: {
    bannersSinceItems(): AnalyzedItem[] {
      const selectedRarity = this.selectedItemRarity;
      const selectedAnalyzedItems =
        this.selectedItemType === ItemType.Character
          ? analyzedCharacters
          : analyzedWeapons;
      return selectedAnalyzedItems
        .filter((analyzedItem) => {
          return (
            selectedRarity === ItemRarity.FourAndFiveStar ||
            analyzedItem.item.rarity === selectedRarity
          );
        })
        .sort((a, b) => {
          return a.bannersSince >= b.bannersSince ? -1 : 1;
        });
    },
    mostBannersSince(): number {
      return this.bannersSinceItems.reduce((largestSoFar, next) => {
        return largestSoFar < next.bannersSince
          ? next.bannersSince
          : largestSoFar;
      }, 0);
    },
  },
});
