
import { defineComponent } from "vue";
import BannersSinceItemMoreInfo from "@/components/BannersSinceItemMoreInfo.vue";
import { ItemType } from "@/types/ItemTypes";

export default defineComponent({
  name: "BannersSinceItem",
  components: { BannersSinceItemMoreInfo },
  props: ["analyzedItem", "mostBannersSince"],
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    imageName() {
      const itemTypeFolder =
        this.analyzedItem.item.itemType === ItemType.Weapon
          ? "weapons"
          : "characters";
      return `assets/images/${itemTypeFolder}/${this.analyzedItem.item.name}.png`;
    },
    barWidth() {
      return this.analyzedItem.bannersSince === 0
        ? 0
        : (this.analyzedItem.bannersSince / this.mostBannersSince) * 0.9 * 100;
    },
    chevronIcon() {
      return this.isExpanded
        ? "assets/icons/chevron-up.svg"
        : "assets/icons/chevron-down.svg";
    },
  },
  methods: {
    handleItemClick() {
      this.isExpanded = !this.isExpanded;
    },
  },
});
