
import { defineComponent } from "vue";
import { ItemType } from "@/types/ItemTypes";
import RarityStars from "@/components/RarityStars.vue";

export default defineComponent({
  name: "ItemCard",
  props: ["item"],
  components: {
    RarityStars,
  },
  computed: {
    imageName(): string {
      const itemTypeFolder =
        this.item.itemType === ItemType.Weapon ? "weapons" : "characters";
      return `assets/images/${itemTypeFolder}/${this.item.name}.png`;
    },
  },
});
