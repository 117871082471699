export enum ItemType {
  Character,
  Weapon,
}

export enum ItemRarity {
  FiveStar,
  FourStar,
  FourAndFiveStar,
}

export interface Item {
  name: string;
  rarity: ItemRarity;
  itemType: ItemType;
}

export enum WeaponType {
  Bow,
  Sword,
  Claymore,
  Catalyst,
  Polearm,
}

export interface Weapon extends Item {
  weaponType: WeaponType;
}
