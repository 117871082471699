
import { defineComponent } from "vue";
import BannersSinceContainer from "@/components/BannersSinceContainer.vue";
import UpcomingBannerContainer from "@/components/UpcomingBannerContainer.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import { upcomingBanner } from "@/data/upcoming-banner";

export default defineComponent({
  name: "SiteHome.vue",
  components: {
    BannersSinceContainer,
    SiteFooter,
    UpcomingBannerContainer,
  },
  computed: {
    showUpcomingBannerContainer(): boolean {
      return (
        upcomingBanner.weapons.length > 0 ||
        upcomingBanner.characters.length > 0
      );
    },
  },
});
