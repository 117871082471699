import { weapons } from "@/data/weapons";
import { Banner } from "@/data/banners";

export const weaponBanners: Banner[] = [
  {
    number: 0,
    name: "1.0",
    items: [
      weapons.AMOS_BOW,
      weapons.AQUILA_FAVONIA,
      weapons.FAVONIUS_LANCE,
      weapons.THE_BELL,
      weapons.THE_FLUTE,
      weapons.THE_STRINGLESS,
      weapons.THE_WIDSITH,
    ],
  },
  {
    number: 1,
    name: "1.0",
    items: [
      weapons.LOST_PRAYER_TO_THE_SACRED_WINDS,
      weapons.WOLFS_GRAVESTONE,
      weapons.DRAGONS_BANE,
      weapons.SACRIFICIAL_BOW,
      weapons.SACRIFICIAL_FRAGMENTS,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.SACRIFICIAL_SWORD,
    ],
  },
  {
    number: 2,
    name: "1.1",
    items: [
      weapons.SKYWARD_HARP,
      weapons.MEMORY_OF_DUST,
      weapons.EYE_OF_PERCEPTION,
      weapons.FAVONIUS_LANCE,
      weapons.RAINSLASHER,
      weapons.RUST,
      weapons.THE_FLUTE,
    ],
  },
  {
    number: 3,
    name: "1.1",
    items: [
      weapons.THE_UNFORGED,
      weapons.VORTEX_VANQUISHER,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_CODEX,
      weapons.FAVONIUS_WARBOW,
      weapons.LIONS_ROAR,
      weapons.THE_BELL,
    ],
  },
  {
    number: 4,
    name: "1.2",
    items: [
      weapons.SUMMIT_SHAPER,
      weapons.SKYWARD_ATLAS,
      weapons.FAVONIUS_GREATSWORD,
      weapons.FAVONIUS_LANCE,
      weapons.THE_STRINGLESS,
      weapons.FAVONIUS_SWORD,
      weapons.SACRIFICIAL_FRAGMENTS,
    ],
  },
  {
    number: 5,
    name: "1.2",
    items: [
      weapons.AMOS_BOW,
      weapons.SKYWARD_PRIDE,
      weapons.DRAGONS_BANE,
      weapons.EYE_OF_PERCEPTION,
      weapons.FAVONIUS_WARBOW,
      weapons.THE_BELL,
      weapons.SACRIFICIAL_SWORD,
    ],
  },
  {
    number: 6,
    name: "1.3",
    items: [
      weapons.PRIMORDIAL_JADE_WINGED_SPEAR,
      weapons.PRIMORDIAL_JADE_CUTTER,
      weapons.EYE_OF_PERCEPTION,
      weapons.FAVONIUS_LANCE,
      weapons.RUST,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.THE_FLUTE,
    ],
  },
  {
    number: 7,
    name: "1.3",
    items: [
      weapons.WOLFS_GRAVESTONE,
      weapons.STAFF_OF_HOMA,
      weapons.LIONS_ROAR,
      weapons.LITHIC_BLADE,
      weapons.LITHIC_SPEAR,
      weapons.SACRIFICIAL_BOW,
      weapons.THE_WIDSITH,
    ],
  },
  {
    number: 8,
    name: "1.4",
    items: [
      weapons.ELEGY_FOR_THE_END,
      weapons.SKYWARD_BLADE,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_GREATSWORD,
      weapons.FAVONIUS_WARBOW,
      weapons.THE_ALLEY_FLASH,
      weapons.WINE_AND_SONG,
    ],
  },
  {
    number: 9,
    name: "1.4",
    items: [
      weapons.LOST_PRAYER_TO_THE_SACRED_WINDS,
      weapons.SKYWARD_HARP,
      weapons.ALLEY_HUNTER,
      weapons.FAVONIUS_CODEX,
      weapons.FAVONIUS_LANCE,
      weapons.FAVONIUS_SWORD,
      weapons.SACRIFICIAL_GREATSWORD,
    ],
  },
  {
    number: 10,
    name: "1.5",
    items: [
      weapons.SUMMIT_SHAPER,
      weapons.MEMORY_OF_DUST,
      weapons.EYE_OF_PERCEPTION,
      weapons.LITHIC_BLADE,
      weapons.LITHIC_SPEAR,
      weapons.SACRIFICIAL_BOW,
      weapons.THE_FLUTE,
    ],
  },
  {
    number: 11,
    name: "1.5",
    items: [
      weapons.SONG_OF_BROKEN_PINES,
      weapons.AQUILA_FAVONIA,
      weapons.DRAGONS_BANE,
      weapons.RAINSLASHER,
      weapons.RUST,
      weapons.SACRIFICIAL_FRAGMENTS,
      weapons.SACRIFICIAL_SWORD,
    ],
  },
  {
    number: 12,
    name: "1.6",
    items: [
      weapons.LOST_PRAYER_TO_THE_SACRED_WINDS,
      weapons.SKYWARD_PRIDE,
      weapons.FAVONIUS_LANCE,
      weapons.LIONS_ROAR,
      weapons.MITTERNACHTS_WALTZ,
      weapons.THE_BELL,
      weapons.THE_WIDSITH,
    ],
  },
  {
    number: 13,
    name: "1.6",
    items: [
      weapons.FREEDOM_SWORN,
      weapons.SKYWARD_ATLAS,
      weapons.ALLEY_HUNTER,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_GREATSWORD,
      weapons.THE_ALLEY_FLASH,
      weapons.WINE_AND_SONG,
    ],
  },
  {
    number: 14,
    name: "2.0",
    items: [
      weapons.MISTSPLITTER_REFORGED,
      weapons.SKYWARD_SPINE,
      weapons.FAVONIUS_CODEX,
      weapons.FAVONIUS_LANCE,
      weapons.FAVONIUS_SWORD,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.THE_STRINGLESS,
    ],
  },
  {
    number: 15,
    name: "2.0",
    items: [
      weapons.SKYWARD_BLADE,
      weapons.THUNDERING_PULSE,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_WARBOW,
      weapons.RAINSLASHER,
      weapons.SACRIFICIAL_FRAGMENTS,
      weapons.SACRIFICIAL_SWORD,
    ],
  },
  {
    number: 16,
    name: "2.1",
    items: [
      weapons.THE_UNFORGED,
      weapons.ENGULFING_LIGHTNING,
      weapons.FAVONIUS_LANCE,
      weapons.LIONS_ROAR,
      weapons.SACRIFICIAL_BOW,
      weapons.THE_BELL,
      weapons.THE_WIDSITH,
    ],
  },
  {
    number: 17,
    name: "2.1",
    items: [
      weapons.PRIMORDIAL_JADE_CUTTER,
      weapons.EVERLASTING_MOONGLOW,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_CODEX,
      weapons.FAVONIUS_GREATSWORD,
      weapons.THE_STRINGLESS,
      weapons.THE_FLUTE,
    ],
  },
  {
    number: 18,
    name: "2.2",
    items: [
      weapons.MEMORY_OF_DUST,
      weapons.POLAR_STAR,
      weapons.AKUOUMARU,
      weapons.EYE_OF_PERCEPTION,
      weapons.FAVONIUS_LANCE,
      weapons.FAVONIUS_SWORD,
      weapons.RUST,
    ],
  },
  {
    number: 19,
    name: "2.2",
    items: [
      weapons.STAFF_OF_HOMA,
      weapons.ELEGY_FOR_THE_END,
      weapons.MOUUNS_MOON,
      weapons.RAINSLASHER,
      weapons.SACRIFICIAL_SWORD,
      weapons.THE_WIDSITH,
      weapons.WAVEBREAKERS_FIN,
    ],
  },
  {
    number: 20,
    name: "2.3",
    items: [
      weapons.SONG_OF_BROKEN_PINES,
      weapons.FREEDOM_SWORN,
      weapons.ALLEY_HUNTER,
      weapons.LIONS_ROAR,
      weapons.DRAGONS_BANE,
      weapons.WINE_AND_SONG,
      weapons.SACRIFICIAL_GREATSWORD,
    ],
  },
  {
    number: 21,
    name: "2.3",
    items: [
      weapons.SKYWARD_HARP,
      weapons.REDHORN_STONETHRESHER,
      weapons.FAVONIUS_LANCE,
      weapons.THE_ALLEY_FLASH,
      weapons.MITTERNACHTS_WALTZ,
      weapons.THE_BELL,
      weapons.SACRIFICIAL_FRAGMENTS,
    ],
  },
  {
    number: 22,
    name: "2.4",
    items: [
      weapons.PRIMORDIAL_JADE_WINGED_SPEAR,
      weapons.CALAMITY_QUELLER,
      weapons.LITHIC_SPEAR,
      weapons.THE_FLUTE,
      weapons.FAVONIUS_GREATSWORD,
      weapons.THE_WIDSITH,
      weapons.FAVONIUS_WARBOW,
    ],
  },
  {
    number: 23,
    name: "2.4",
    items: [
      weapons.AMOS_BOW,
      weapons.VORTEX_VANQUISHER,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_SWORD,
      weapons.LITHIC_BLADE,
      weapons.FAVONIUS_CODEX,
      weapons.SACRIFICIAL_BOW,
    ],
  },
  {
    number: 24,
    name: "2.5",
    items: [
      weapons.PRIMORDIAL_JADE_CUTTER,
      weapons.KAGURAS_VERITY,
      weapons.WAVEBREAKERS_FIN,
      weapons.SACRIFICIAL_SWORD,
      weapons.RAINSLASHER,
      weapons.EYE_OF_PERCEPTION,
      weapons.THE_STRINGLESS,
    ],
  },
  {
    number: 25,
    name: "2.5",
    items: [
      weapons.ENGULFING_LIGHTNING,
      weapons.EVERLASTING_MOONGLOW,
      weapons.AKUOUMARU,
      weapons.MOUUNS_MOON,
      weapons.LIONS_ROAR,
      weapons.FAVONIUS_LANCE,
      weapons.SACRIFICIAL_FRAGMENTS,
    ],
  },
  {
    number: 26,
    name: "2.6",
    items: [
      weapons.ELEGY_FOR_THE_END,
      weapons.HARAN_GEPPAKU_FUTSU,
      weapons.RUST,
      weapons.THE_FLUTE,
      weapons.DRAGONS_BANE,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.THE_WIDSITH,
    ],
  },
  {
    number: 27,
    name: "2.6",
    items: [
      weapons.MISTSPLITTER_REFORGED,
      weapons.THE_UNFORGED,
      weapons.THE_BELL,
      weapons.FAVONIUS_WARBOW,
      weapons.FAVONIUS_SWORD,
      weapons.FAVONIUS_LANCE,
      weapons.FAVONIUS_CODEX,
    ],
  },
  {
    number: 28,
    name: "2.7",
    items: [
      weapons.PRIMORDIAL_JADE_WINGED_SPEAR,
      weapons.AQUA_SIMULACRA,
      weapons.LITHIC_SPEAR,
      weapons.SACRIFICIAL_SWORD,
      weapons.SACRIFICIAL_BOW,
      weapons.EYE_OF_PERCEPTION,
      weapons.FAVONIUS_GREATSWORD,
    ],
  },
  {
    number: 29,
    name: "2.7",
    items: [
      weapons.MEMORY_OF_DUST,
      weapons.REDHORN_STONETHRESHER,
      weapons.LITHIC_BLADE,
      weapons.LIONS_ROAR,
      weapons.DRAGONS_BANE,
      weapons.SACRIFICIAL_FRAGMENTS,
      weapons.THE_STRINGLESS,
    ],
  },
  {
    number: 30,
    name: "2.8",
    items: [
      weapons.FREEDOM_SWORN,
      weapons.LOST_PRAYER_TO_THE_SACRED_WINDS,
      weapons.THE_ALLEY_FLASH,
      weapons.MITTERNACHTS_WALTZ,
      weapons.RAINSLASHER,
      weapons.FAVONIUS_LANCE,
      weapons.THE_WIDSITH,
    ],
  },
  {
    number: 31,
    name: "2.8",
    items: [
      weapons.THUNDERING_PULSE,
      weapons.SUMMIT_SHAPER,
      weapons.WINE_AND_SONG,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.THE_FLUTE,
      weapons.DRAGONS_BANE,
      weapons.ALLEY_HUNTER,
    ],
  },
  {
    number: 32,
    name: "3.0",
    items: [
      weapons.HUNTERS_PATH,
      weapons.VORTEX_VANQUISHER,
      weapons.FAVONIUS_SWORD,
      weapons.THE_BELL,
      weapons.FAVONIUS_LANCE,
      weapons.FAVONIUS_CODEX,
      weapons.THE_STRINGLESS,
    ],
  },
  {
    number: 33,
    name: "3.0",
    items: [
      weapons.AMOS_BOW,
      weapons.EVERLASTING_MOONGLOW,
      weapons.SACRIFICIAL_SWORD,
      weapons.EYE_OF_PERCEPTION,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_GREATSWORD,
      weapons.RUST,
    ],
  },
  {
    number: 34,
    name: "3.1",
    items: [
      weapons.ELEGY_FOR_THE_END,
      weapons.STAFF_OF_THE_SCARLET_SANDS,
      weapons.MAKHAIRA_AQUAMARINE,
      weapons.LIONS_ROAR,
      weapons.FAVONIUS_LANCE,
      weapons.SACRIFICIAL_FRAGMENTS,
      weapons.FAVONIUS_WARBOW,
    ],
  },
  {
    number: 35,
    name: "3.1",
    items: [
      weapons.KEY_OF_KHAJ_NISUT,
      weapons.PRIMORDIAL_JADE_CUTTER,
      weapons.XIPHOS_MOONLIGHT,
      weapons.SACRIFICIAL_BOW,
      weapons.DRAGONS_BANE,
      weapons.RAINSLASHER,
      weapons.WANDERING_EVENSTAR,
    ],
  },
  {
    number: 36,
    name: "3.2",
    items: [
      weapons.THUNDERING_PULSE,
      weapons.A_THOUSAND_FLOATING_DREAMS,
      weapons.THE_FLUTE,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.FAVONIUS_LANCE,
      weapons.THE_WIDSITH,
      weapons.RUST,
    ],
  },
  {
    number: 37,
    name: "3.2",
    items: [
      weapons.KAGURAS_VERITY,
      weapons.POLAR_STAR,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_CODEX,
      weapons.THE_STRINGLESS,
      weapons.THE_BELL,
      weapons.FAVONIUS_SWORD,
    ],
  },
  {
    number: 38,
    name: "3.3",
    items: [
      weapons.REDHORN_STONETHRESHER,
      weapons.TULAYTULLAHS_REMEMBRANCE,
      weapons.WAVEBREAKERS_FIN,
      weapons.SACRIFICIAL_SWORD,
      weapons.FAVONIUS_GREATSWORD,
      weapons.EYE_OF_PERCEPTION,
      weapons.FAVONIUS_WARBOW,
    ],
  },
  {
    number: 39,
    name: "3.3",
    items: [
      weapons.HARAN_GEPPAKU_FUTSU,
      weapons.ENGULFING_LIGHTNING,
      weapons.AKUOUMARU,
      weapons.MOUUNS_MOON,
      weapons.LIONS_ROAR,
      weapons.FAVONIUS_LANCE,
      weapons.SACRIFICIAL_FRAGMENTS,
    ],
  },
  {
    number: 40,
    name: "3.4",
    items: [
      weapons.PRIMORDIAL_JADE_WINGED_SPEAR,
      weapons.LIGHT_OF_FOLIAR_INCISION,
      weapons.LITHIC_SPEAR,
      weapons.THE_FLUTE,
      weapons.RAINSLASHER,
      weapons.THE_WIDSITH,
      weapons.SACRIFICIAL_BOW,
    ],
  },
  {
    number: 41,
    name: "3.4",
    items: [
      weapons.AQUA_SIMULACRA,
      weapons.STAFF_OF_HOMA,
      weapons.LITHIC_BLADE,
      weapons.FAVONIUS_SWORD,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_CODEX,
      weapons.RUST,
    ],
  },
  {
    number: 42,
    name: "3.5",
    items: [
      weapons.BEACON_OF_THE_REED_SEA,
      weapons.STAFF_OF_THE_SCARLET_SANDS,
      weapons.THE_ALLEY_FLASH,
      weapons.ALLEY_HUNTER,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.DRAGONS_BANE,
      weapons.EYE_OF_PERCEPTION,
    ],
  },
  {
    number: 43,
    name: "3.5",
    items: [
      weapons.MISTSPLITTER_REFORGED,
      weapons.CALAMITY_QUELLER,
      weapons.WINE_AND_SONG,
      weapons.SACRIFICIAL_SWORD,
      weapons.THE_BELL,
      weapons.FAVONIUS_LANCE,
      weapons.FAVONIUS_WARBOW,
    ],
  },
  {
    number: 44,
    name: "3.6",
    items: [
      weapons.A_THOUSAND_FLOATING_DREAMS,
      weapons.KEY_OF_KHAJ_NISUT,
      weapons.XIPHOS_MOONLIGHT,
      weapons.FAVONIUS_GREATSWORD,
      weapons.DRAGONS_BANE,
      weapons.SACRIFICIAL_FRAGMENTS,
      weapons.THE_STRINGLESS,
    ],
  },
  {
    number: 45,
    name: "3.6",
    items: [
      weapons.AMOS_BOW,
      weapons.JADEFALLS_SPLENDOR,
      weapons.MAKHAIRA_AQUAMARINE,
      weapons.WANDERING_EVENSTAR,
      weapons.LIONS_ROAR,
      weapons.SACRIFICIAL_BOW,
      weapons.FAVONIUS_LANCE,
    ],
  },
  {
    number: 46,
    name: "3.7",
    items: [
      weapons.THUNDERING_PULSE,
      weapons.KAGURAS_VERITY,
      weapons.AKUOUMARU,
      weapons.THE_FLUTE,
      weapons.DRAGONS_BANE,
      weapons.THE_WIDSITH,
      weapons.RUST,
    ],
  },
  {
    number: 47,
    name: "3.7",
    items: [
      weapons.FREEDOM_SWORN,
      weapons.LIGHT_OF_FOLIAR_INCISION,
      weapons.FAVONIUS_SWORD,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.FAVONIUS_CODEX,
      weapons.WAVEBREAKERS_FIN,
      weapons.MOUUNS_MOON,
    ],
  },
  {
    number: 48,
    name: "3.8",
    items: [
      weapons.LOST_PRAYER_TO_THE_SACRED_WINDS,
      weapons.SONG_OF_BROKEN_PINES,
      weapons.RAINSLASHER,
      weapons.FAVONIUS_LANCE,
      weapons.EYE_OF_PERCEPTION,
      weapons.THE_ALLEY_FLASH,
      weapons.MITTERNACHTS_WALTZ,
    ],
  },
  {
    number: 49,
    name: "3.8",
    items: [
      weapons.EVERLASTING_MOONGLOW,
      weapons.TULAYTULLAHS_REMEMBRANCE,
      weapons.LIONS_ROAR,
      weapons.THE_BELL,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_WARBOW,
      weapons.WINE_AND_SONG,
    ],
  },
  {
    number: 50,
    name: "4.0",
    items: [
      weapons.AQUA_SIMULACRA,
      weapons.THE_FIRST_GREAT_MAGIC,
      weapons.SACRIFICIAL_SWORD,
      weapons.FAVONIUS_GREATSWORD,
      weapons.FAVONIUS_LANCE,
      weapons.SACRIFICIAL_FRAGMENTS,
      weapons.SACRIFICIAL_BOW,
    ],
  },
  {
    number: 51,
    name: "4.0",
    items: [
      weapons.VORTEX_VANQUISHER,
      weapons.POLAR_STAR,
      weapons.THE_FLUTE,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.DRAGONS_BANE,
      weapons.THE_WIDSITH,
      weapons.RUST,
    ],
  },
  {
    number: 52,
    name: "4.1",
    items: [
      weapons.STAFF_OF_HOMA,
      weapons.TOME_OF_THE_ETERNAL_FLOW,
      weapons.FAVONIUS_LANCE,
      weapons.FAVONIUS_CODEX,
      weapons.MITTERNACHTS_WALTZ,
      weapons.THE_DOCKHANDS_ASSISTANT,
      weapons.PORTABLE_POWER_SAW,
    ],
  },
  {
    number: 53,
    name: "4.1",
    items: [
      weapons.ELEGY_FOR_THE_END,
      weapons.CASHFLOW_SUPERVISION,
      weapons.RAINSLASHER,
      weapons.EYE_OF_PERCEPTION,
      weapons.PROSPECTORS_DRILL,
      weapons.RANGE_GAUGE,
    ],
  },
  {
    number: 54,
    name: "4.2",
    items: [
      weapons.JADEFALLS_SPLENDOR,
      weapons.SPLENDOR_OF_TRANQUIL_WATERS,
      weapons.SACRIFICIAL_SWORD,
      weapons.THE_BELL,
      weapons.DRAGONS_BANE,
      weapons.SACRIFICIAL_FRAGMENTS,
      weapons.THE_STRINGLESS,
    ],
  },
  {
    number: 55,
    name: "4.2",
    items: [
      weapons.HARAN_GEPPAKU_FUTSU,
      weapons.STAFF_OF_THE_SCARLET_SANDS,
      weapons.LIONS_ROAR,
      weapons.FAVONIUS_GREATSWORD,
      weapons.FAVONIUS_LANCE,
      weapons.THE_WIDSITH,
      weapons.FAVONIUS_WARBOW,
    ],
  },
  {
    number: 56,
    name: "4.3",
    items: [
      weapons.MISTSPLITTER_REFORGED,
      weapons.VERDICT,
      weapons.THE_FLUTE,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_CODEX,
      weapons.AKUOUMARU,
      weapons.MOUUNS_MOON,
    ],
  },
  {
    number: 57,
    name: "4.3",
    items: [
      weapons.THUNDERING_PULSE,
      weapons.ENGULFING_LIGHTNING,
      weapons.FAVONIUS_SWORD,
      weapons.RAINSLASHER,
      weapons.EYE_OF_PERCEPTION,
      weapons.RUST,
      weapons.WAVEBREAKERS_FIN,
    ],
  },
  {
    number: 58,
    name: "4.4",
    items: [
      weapons.A_THOUSAND_FLOATING_DREAMS,
      weapons.CRANES_ECHOING_CALL,
      weapons.SACRIFICIAL_SWORD,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.SACRIFICIAL_FRAGMENTS,
      weapons.SACRIFICIAL_BOW,
      weapons.LITHIC_SPEAR,
    ],
  },
  {
    number: 59,
    name: "4.4",
    items: [
      weapons.PRIMORDIAL_JADE_WINGED_SPEAR,
      weapons.KAGURAS_VERITY,
      weapons.LIONS_ROAR,
      weapons.FAVONIUS_LANCE,
      weapons.THE_WIDSITH,
      weapons.THE_STRINGLESS,
      weapons.LITHIC_BLADE,
    ],
  },
  {
    number: 60,
    name: "4.5",
    items: [
      weapons.REDHORN_STONETHRESHER,
      weapons.URAKU_MISUGIRI,
      weapons.ALLEY_HUNTER,
      weapons.THE_BELL,
      weapons.DRAGONS_BANE,
      weapons.FAVONIUS_CODEX,
      weapons.THE_ALLEY_FLASH,
    ],
  },
  {
    number: 61,
    name: "4.5",
    items: [
      weapons.FREEDOM_SWORN,
      weapons.TOME_OF_THE_ETERNAL_FLOW,
      weapons.THE_FLUTE,
      weapons.FAVONIUS_GREATSWORD,
      weapons.FAVONIUS_LANCE,
      weapons.WINE_AND_SONG,
      weapons.MITTERNACHTS_WALTZ,
    ],
  },
  {
    number: 62,
    name: "4.6",
    items: [
      weapons.THE_FIRST_GREAT_MAGIC,
      weapons.CRIMSON_MOONS_SEMBLANCE,
      weapons.DRAGONS_BANE,
      weapons.EYE_OF_PERCEPTION,
      weapons.FAVONIUS_WARBOW,
      weapons.THE_DOCKHANDS_ASSISTANT,
      weapons.PORTABLE_POWER_SAW,
    ],
  },
  {
    number: 63,
    name: "4.6",
    items: [
      weapons.TULAYTULLAHS_REMEMBRANCE,
      weapons.JADEFALLS_SPLENDOR,
      weapons.FAVONIUS_SWORD,
      weapons.RAINSLASHER,
      weapons.SACRIFICIAL_FRAGMENTS,
      weapons.PROSPECTORS_DRILL,
      weapons.RANGE_GAUGE,
    ],
  },
  {
    number: 64,
    name: "4.7",
    items: [
      weapons.LIGHT_OF_FOLIAR_INCISION,
      weapons.ABSOLUTION,
      weapons.SACRIFICIAL_SWORD,
      weapons.SACRIFICIAL_GREATSWORD,
      weapons.DRAGONS_BANE,
      weapons.THE_WIDSITH,
      weapons.THE_STRINGLESS,
    ],
  },
  {
    number: 65,
    name: "4.7",
    items: [
      weapons.SILVERSHOWER_HEARTSTRINGS,
      weapons.SPLENDOR_OF_TRANQUIL_WATERS,
      weapons.LITHIC_BLADE,
      weapons.LIONS_ROAR,
      weapons.FAVONIUS_LANCE,
      weapons.FAVONIUS_CODEX,
      weapons.SACRIFICIAL_BOW,
    ],
  },
];
