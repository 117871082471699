
import { defineComponent } from "vue";
import RarityStars from "@/components/RarityStars.vue";
import BannerItemTimeline from "@/components/BannerItemTimeline.vue";

enum CONSISTENCY {
  UNDEFINED,
  CONSISTENT,
  SOMEWHAT_CONSISTENT,
  INCONSISTENT,
}

export default defineComponent({
  name: "BannersSinceItemMoreInfo.vue",
  props: ["analyzedItem"],
  components: {
    BannerItemTimeline,
    RarityStars,
  },
  computed: {
    consistency(): CONSISTENCY {
      const cv = this.analyzedItem.coefficientOfVariation;
      if (cv === -1) {
        return CONSISTENCY.UNDEFINED;
      }
      if (cv <= 15) {
        return CONSISTENCY.CONSISTENT;
      }
      if (cv <= 40) {
        return CONSISTENCY.SOMEWHAT_CONSISTENT;
      }
      return CONSISTENCY.INCONSISTENT;
    },
    rerunText(): string {
      const rerunPeriodFormatted = parseFloat(
        this.analyzedItem.averageRerunPeriod
      ).toFixed(2);
      return this.analyzedItem.averageRerunPeriod
        ? `Every ${rerunPeriodFormatted} banners`
        : "No reruns yet";
    },
    consistencyText(): string {
      const consistencyTextMapping: { [key: string]: string } = {
        [CONSISTENCY.UNDEFINED]: "No reruns yet",
        [CONSISTENCY.CONSISTENT]: "Consistent",
        [CONSISTENCY.SOMEWHAT_CONSISTENT]: "Somewhat Consistent",
        [CONSISTENCY.INCONSISTENT]: "Inconsistent",
      };
      return consistencyTextMapping[this.consistency];
    },
    coefficientText(): string {
      const cv = this.analyzedItem.coefficientOfVariation;
      const cvFormatted = parseFloat(cv).toFixed(2);
      return cv === -1 ? "No reruns yet" : `${cvFormatted}%`;
    },
  },
  methods: {
    consistencyColorClass(): string {
      const consistencyColorMapping: { [key: string]: string } = {
        [CONSISTENCY.UNDEFINED]: "",
        [CONSISTENCY.CONSISTENT]: "green",
        [CONSISTENCY.SOMEWHAT_CONSISTENT]: "yellow",
        [CONSISTENCY.INCONSISTENT]: "red",
      };
      return consistencyColorMapping[this.consistency];
    },
  },
});
