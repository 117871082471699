import { ItemRarity, ItemType, Weapon, WeaponType } from "@/types/ItemTypes";

export const weapons: { [key: string]: Weapon } = {
  A_THOUSAND_FLOATING_DREAMS: {
    name: "A Thousand Floating Dreams",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  ABSOLUTION: {
    name: "Absolution",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  AKUOUMARU: {
    name: "Akuoumaru",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  ALLEY_HUNTER: {
    name: "Alley Hunter",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  AMOS_BOW: {
    name: "Amos' Bow",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  AQUA_SIMULACRA: {
    name: "Aqua Simulacra",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  AQUILA_FAVONIA: {
    name: "Aquila Favonia",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  BEACON_OF_THE_REED_SEA: {
    name: "Beacon of the Reed Sea",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  CALAMITY_QUELLER: {
    name: "Calamity Queller",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  CASHFLOW_SUPERVISION: {
    name: "Cashflow Supervision",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  CRANES_ECHOING_CALL: {
    name: "Crane's Echoing Call",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  CRIMSON_MOONS_SEMBLANCE: {
    name: "Crimson Moon's Semblance",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  DRAGONS_BANE: {
    name: "Dragon's Bane",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  ELEGY_FOR_THE_END: {
    name: "Elegy for the End",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  ENGULFING_LIGHTNING: {
    name: "Engulfing Lightning",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  EVERLASTING_MOONGLOW: {
    name: "Everlasting Moonglow",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  EYE_OF_PERCEPTION: {
    name: "Eye of Perception",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  FAVONIUS_CODEX: {
    name: "Favonius Codex",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  FAVONIUS_GREATSWORD: {
    name: "Favonius Greatsword",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  FAVONIUS_LANCE: {
    name: "Favonius Lance",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  FAVONIUS_SWORD: {
    name: "Favonius Sword",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  FAVONIUS_WARBOW: {
    name: "Favonius Warbow",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  FREEDOM_SWORN: {
    name: "Freedom-Sworn",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  HARAN_GEPPAKU_FUTSU: {
    name: "Haran Geppaku Futsu",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  HUNTERS_PATH: {
    name: "Hunter's Path",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  JADEFALLS_SPLENDOR: {
    name: "Jadefall's Splendor",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  KAGURAS_VERITY: {
    name: "Kagura's Verity",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  KEY_OF_KHAJ_NISUT: {
    name: "Key of Khaj-Nisut",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  LIGHT_OF_FOLIAR_INCISION: {
    name: "Light of Foliar Incision",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  LIONS_ROAR: {
    name: "Lion's Roar",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  LITHIC_BLADE: {
    name: "Lithic Blade",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  LITHIC_SPEAR: {
    name: "Lithic Spear",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  LOST_PRAYER_TO_THE_SACRED_WINDS: {
    name: "Lost Prayer to the Sacred Winds",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  MAKHAIRA_AQUAMARINE: {
    name: "Makhaira Aquamarine",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  MEMORY_OF_DUST: {
    name: "Memory of Dust",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  MISTSPLITTER_REFORGED: {
    name: "Mistsplitter Reforged",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  MITTERNACHTS_WALTZ: {
    name: "Mitternachts Waltz",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  MOUUNS_MOON: {
    name: "Mouun's Moon",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  POLAR_STAR: {
    name: "Polar Star",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  PORTABLE_POWER_SAW: {
    name: "Portable Power Saw",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  PRIMORDIAL_JADE_CUTTER: {
    name: "Primordial Jade Cutter",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  PRIMORDIAL_JADE_WINGED_SPEAR: {
    name: "Primordial Jade Winged Spear",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  PROSPECTORS_DRILL: {
    name: "Prospector's Drill",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  RANGE_GAUGE: {
    name: "Range Gauge",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  RAINSLASHER: {
    name: "Rainslasher",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  REDHORN_STONETHRESHER: {
    name: "Redhorn Stonethresher",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  RUST: {
    name: "Rust",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  SACRIFICIAL_BOW: {
    name: "Sacrificial Bow",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  SACRIFICIAL_FRAGMENTS: {
    name: "Sacrificial Fragments",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  SACRIFICIAL_GREATSWORD: {
    name: "Sacrificial Greatsword",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  SACRIFICIAL_SWORD: {
    name: "Sacrificial Sword",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  SILVERSHOWER_HEARTSTRINGS: {
    name: "Silvershower Heartstrings",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  SKYWARD_ATLAS: {
    name: "Skyward Atlas",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  SKYWARD_BLADE: {
    name: "Skyward Blade",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  SKYWARD_HARP: {
    name: "Skyward Harp",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  SKYWARD_PRIDE: {
    name: "Skyward Pride",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  SKYWARD_SPINE: {
    name: "Skyward Spine",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  SONG_OF_BROKEN_PINES: {
    name: "Song of Broken Pines",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  SPLENDOR_OF_TRANQUIL_WATERS: {
    name: "Splendor of Tranquil Waters",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  STAFF_OF_HOMA: {
    name: "Staff of Homa",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  STAFF_OF_THE_SCARLET_SANDS: {
    name: "Staff of the Scarlet Sands",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  SUMMIT_SHAPER: {
    name: "Summit Shaper",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  THE_ALLEY_FLASH: {
    name: "The Alley FLash",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  THE_BELL: {
    name: "The Bell",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  THE_DOCKHANDS_ASSISTANT: {
    name: "The Dockhand's Assistant",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  THE_FIRST_GREAT_MAGIC: {
    name: "The First Great Magic",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  THE_FLUTE: {
    name: "The Flute",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  THE_STRINGLESS: {
    name: "The Stringless",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  THE_UNFORGED: {
    name: "The Unforged",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  THE_WIDSITH: {
    name: "The Widsith",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  THUNDERING_PULSE: {
    name: "Thundering Pulse",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Bow,
  },
  TOME_OF_THE_ETERNAL_FLOW: {
    name: "Tome of the Eternal Flow",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  TULAYTULLAHS_REMEMBRANCE: {
    name: "Tulaytullah's Remembrance",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  URAKU_MISUGIRI: {
    name: "Uraku Misugiri",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
  VERDICT: {
    name: "Verdict",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  VORTEX_VANQUISHER: {
    name: "Vortex Vanquisher",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  WANDERING_EVENSTAR: {
    name: "Wandering Evenstar",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  WAVEBREAKERS_FIN: {
    name: "Wavebreaker's Fin",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Polearm,
  },
  WINE_AND_SONG: {
    name: "Wine and Song",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Catalyst,
  },
  WOLFS_GRAVESTONE: {
    name: "Wolf's Gravestone",
    rarity: ItemRarity.FiveStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Claymore,
  },
  XIPHOS_MOONLIGHT: {
    name: "Xiphos' Moonlight",
    rarity: ItemRarity.FourStar,
    itemType: ItemType.Weapon,
    weaponType: WeaponType.Sword,
  },
};
