import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18b8bc34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upcoming-banner-container" }
const _hoisted_2 = {
  key: 0,
  class: "characters-container"
}
const _hoisted_3 = {
  key: 1,
  class: "weapons-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemCard = _resolveComponent("ItemCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.headerText), 1),
    (_ctx.showCharacters)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.characters, (item) => {
            return (_openBlock(), _createBlock(_component_ItemCard, {
              item: item,
              key: item.name
            }, null, 8, ["item"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.showWeapons)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weapons, (item) => {
            return (_openBlock(), _createBlock(_component_ItemCard, {
              item: item,
              key: item.name
            }, null, 8, ["item"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}