import { Banner, banners } from "@/data/banners";
import { characters } from "@/data/characters";
import { Item } from "@/types/ItemTypes";
import { weapons } from "@/data/weapons";

export interface AnalyzedItem {
  item: Item;
  bannersSince: number;
  averageRerunPeriod: number;
}

export const bannerHasItem = (item: Item, banner: Banner) => {
  return banner.items.find((bannerItem) => bannerItem === item);
};

const getBannersSince = (item: Item, banners: Banner[]) => {
  const lastAppearance = banners.findLastIndex((banner: Banner) => {
    return bannerHasItem(item, banner);
  });
  return banners.length - 1 - lastAppearance;
};

const getRerunLengths = (item: Item, banners: Banner[]) => {
  const rerunLengths = [];
  let cursor = banners.findIndex((banner: Banner) => {
    return bannerHasItem(item, banner);
  });
  let currentLength = 0;
  while (cursor + 1 !== banners.length) {
    cursor++;
    currentLength++;
    if (bannerHasItem(item, banners[cursor])) {
      rerunLengths.push(currentLength);
      currentLength = 0;
    }
  }
  return rerunLengths;
};

const getAverageRerunPeriod = (rerunLengths: number[]) => {
  return rerunLengths.length
    ? rerunLengths.reduce((runningSum, length) => runningSum + length, 0) /
        rerunLengths.length
    : 0;
};

const getCoefficientOfVariation = (
  averageRerunPeriod: number,
  rerunLengths: number[]
) => {
  if (rerunLengths.length === 0) {
    return -1;
  }
  const squaredDiffs = rerunLengths.map((length) => {
    return Math.pow(length - averageRerunPeriod, 2);
  });
  const squaredDiffsMean =
    squaredDiffs.reduce(
      (runningSum, squaredDiff) => runningSum + squaredDiff,
      0
    ) / squaredDiffs.length;
  return (Math.sqrt(squaredDiffsMean) / averageRerunPeriod) * 100;
};

const analyzeItem = (item: Item, banners: Banner[]) => {
  const bannersSince = getBannersSince(item, banners);
  const rerunLengths = getRerunLengths(item, banners);
  const averageRerunPeriod = getAverageRerunPeriod(rerunLengths);
  const coefficientOfVariation = getCoefficientOfVariation(
    averageRerunPeriod,
    rerunLengths
  );
  return {
    item,
    bannersSince,
    averageRerunPeriod,
    coefficientOfVariation,
  };
};

const hasRun = (item: Item, banners: Banner[]) => {
  return banners.find((banner: Banner) => {
    return bannerHasItem(item, banner);
  });
};

const getAnalyzedItems = (
  items: { [key: string]: Item },
  banners: Banner[]
) => {
  const analyzedItems: AnalyzedItem[] = [];
  const itemNames = Object.keys(items);
  itemNames.forEach((itemName) => {
    // only analyze items that have appeared on a banner
    if (hasRun(items[itemName], banners)) {
      analyzedItems.push(analyzeItem(items[itemName], banners));
    }
  });
  return analyzedItems;
};

export const analyzedCharacters = getAnalyzedItems(
  characters,
  banners.characterBanners
);

export const analyzedWeapons = getAnalyzedItems(weapons, banners.weaponBanners);
