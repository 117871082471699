import { Item, Weapon } from "@/types/ItemTypes";
import { weaponBanners } from "@/data/weapon-banners";
import { characterBanners } from "@/data/character-banners";

export interface Banner {
  number: number;
  name: string;
  items: Item[] | Weapon[];
}

// Ordered list of all banners in the history of Genshin Impact
export const banners: { [bannerType: string]: Banner[] } = {
  characterBanners,
  weaponBanners,
};
