import { Banner } from "@/data/banners";
import { characters } from "@/data/characters";

export const characterBanners: Banner[] = [
  {
    number: 0,
    name: "1.0",
    items: [
      characters.VENTI,
      characters.BARBARA,
      characters.XIANGLING,
      characters.FISCHL,
    ],
  },
  {
    number: 1,
    name: "1.0",
    items: [
      characters.KLEE,
      characters.NOELLE,
      characters.SUCROSE,
      characters.XINGQIU,
    ],
  },
  {
    number: 2,
    name: "1.1",
    items: [
      characters.CHILDE,
      characters.BEIDOU,
      characters.NINGGUANG,
      characters.DIONA,
    ],
  },
  {
    number: 3,
    name: "1.1",
    items: [
      characters.ZHONGLI,
      characters.CHONGYUN,
      characters.RAZOR,
      characters.XINYAN,
    ],
  },
  {
    number: 4,
    name: "1.2",
    items: [
      characters.ALBEDO,
      characters.BENNETT,
      characters.FISCHL,
      characters.SUCROSE,
    ],
  },
  {
    number: 5,
    name: "1.2",
    items: [
      characters.GANYU,
      characters.EULA,
      characters.NOELLE,
      characters.XIANGLING,
      characters.XINGQIU,
    ],
  },
  {
    number: 6,
    name: "1.3",
    items: [
      characters.XIAO,
      characters.BEIDOU,
      characters.DIONA,
      characters.XINYAN,
    ],
  },
  {
    number: 7,
    name: "1.3",
    items: [characters.BARBARA, characters.BENNETT, characters.NINGGUANG],
  },
  {
    number: 8,
    name: "1.3",
    items: [
      characters.HU_TAO,
      characters.CHONGYUN,
      characters.XIANGLING,
      characters.XINGQIU,
    ],
  },
  {
    number: 9,
    name: "1.4",
    items: [
      characters.VENTI,
      characters.NOELLE,
      characters.RAZOR,
      characters.SUCROSE,
    ],
  },
  {
    number: 10,
    name: "1.4",
    items: [
      characters.CHILDE,
      characters.ROSARIA,
      characters.BARBARA,
      characters.FISCHL,
    ],
  },
  {
    number: 11,
    name: "1.5",
    items: [
      characters.ZHONGLI,
      characters.YANFEI,
      characters.DIONA,
      characters.NOELLE,
    ],
  },
  {
    number: 12,
    name: "1.5",
    items: [
      characters.EULA,
      characters.BEIDOU,
      characters.XINGQIU,
      characters.XINYAN,
    ],
  },
  {
    number: 13,
    name: "1.6",
    items: [
      characters.KLEE,
      characters.BARBARA,
      characters.FISCHL,
      characters.SUCROSE,
    ],
  },
  {
    number: 14,
    name: "1.6",
    items: [
      characters.KAZUHA,
      characters.BENNETT,
      characters.RAZOR,
      characters.ROSARIA,
    ],
  },
  {
    number: 15,
    name: "2.0",
    items: [
      characters.AYAKA,
      characters.CHONGYUN,
      characters.NINGGUANG,
      characters.YANFEI,
    ],
  },
  {
    number: 16,
    name: "2.0",
    items: [
      characters.YOIMIYA,
      characters.SAYU,
      characters.DIONA,
      characters.XINYAN,
    ],
  },
  {
    number: 17,
    name: "2.1",
    items: [
      characters.RAIDEN,
      characters.KUJOU_SARA,
      characters.SUCROSE,
      characters.XIANGLING,
    ],
  },
  {
    number: 18,
    name: "2.1",
    items: [
      characters.KOKOMI,
      characters.BEIDOU,
      characters.ROSARIA,
      characters.XINGQIU,
    ],
  },
  {
    number: 19,
    name: "2.2",
    items: [
      characters.CHILDE,
      characters.CHONGYUN,
      characters.NINGGUANG,
      characters.YANFEI,
    ],
  },
  {
    number: 20,
    name: "2.2",
    items: [
      characters.HU_TAO,
      characters.THOMA,
      characters.SAYU,
      characters.DIONA,
    ],
  },
  {
    number: 21,
    name: "2.3",
    items: [
      characters.EULA,
      characters.ALBEDO,
      characters.BENNETT,
      characters.ROSARIA,
      characters.NOELLE,
    ],
  },
  {
    number: 22,
    name: "2.3",
    items: [
      characters.ITTO,
      characters.GOROU,
      characters.XIANGLING,
      characters.BARBARA,
    ],
  },
  {
    number: 23,
    name: "2.4",
    items: [
      characters.XIAO,
      characters.SHENHE,
      characters.CHONGYUN,
      characters.NINGGUANG,
      characters.YUN_JIN,
    ],
  },
  {
    number: 24,
    name: "2.4",
    items: [
      characters.ZHONGLI,
      characters.GANYU,
      characters.XINGQIU,
      characters.BEIDOU,
      characters.YANFEI,
    ],
  },
  {
    number: 25,
    name: "2.5",
    items: [
      characters.YAE_MIKO,
      characters.THOMA,
      characters.FISCHL,
      characters.DIONA,
    ],
  },
  {
    number: 26,
    name: "2.5",
    items: [
      characters.RAIDEN,
      characters.KOKOMI,
      characters.BENNETT,
      characters.XINYAN,
      characters.KUJOU_SARA,
    ],
  },
  {
    number: 27,
    name: "2.6",
    items: [
      characters.AYATO,
      characters.VENTI,
      characters.SUCROSE,
      characters.XIANGLING,
      characters.YUN_JIN,
    ],
  },
  {
    number: 28,
    name: "2.6",
    items: [
      characters.AYAKA,
      characters.ROSARIA,
      characters.RAZOR,
      characters.SAYU,
    ],
  },
  {
    number: 29,
    name: "2.7",
    items: [
      characters.XIAO,
      characters.YELAN,
      characters.YANFEI,
      characters.BARBARA,
      characters.NOELLE,
    ],
  },
  {
    number: 30,
    name: "2.7",
    items: [
      characters.ITTO,
      characters.KUKI_SHINOBU,
      characters.GOROU,
      characters.CHONGYUN,
    ],
  },
  {
    number: 31,
    name: "2.8",
    items: [
      characters.KLEE,
      characters.KAZUHA,
      characters.HEIZOU,
      characters.THOMA,
      characters.NINGGUANG,
    ],
  },
  {
    number: 32,
    name: "2.8",
    items: [
      characters.YOIMIYA,
      characters.YUN_JIN,
      characters.XINYAN,
      characters.BENNETT,
    ],
  },
  {
    number: 33,
    name: "3.0",
    items: [
      characters.ZHONGLI,
      characters.COLLEI,
      characters.DIONA,
      characters.FISCHL,
    ],
  },
  {
    number: 34,
    name: "3.0",
    items: [
      characters.GANYU,
      characters.KOKOMI,
      characters.DORI,
      characters.XINGQIU,
      characters.SUCROSE,
    ],
  },
  {
    number: 35,
    name: "3.1",
    items: [
      characters.CYNO,
      characters.VENTI,
      characters.CANDACE,
      characters.KUKI_SHINOBU,
      characters.SAYU,
    ],
  },
  {
    number: 36,
    name: "3.1",
    items: [
      characters.ALBEDO,
      characters.NILOU,
      characters.BARBARA,
      characters.XIANGLING,
      characters.BEIDOU,
    ],
  },
  {
    number: 37,
    name: "3.2",
    items: [
      characters.YOIMIYA,
      characters.NAHIDA,
      characters.BENNETT,
      characters.RAZOR,
      characters.NOELLE,
    ],
  },
  {
    number: 38,
    name: "3.2",
    items: [
      characters.YAE_MIKO,
      characters.CHILDE,
      characters.LAYLA,
      characters.THOMA,
      characters.HEIZOU,
    ],
  },
  {
    number: 39,
    name: "3.3",
    items: [
      characters.ITTO,
      characters.SCARAMOUCHE,
      characters.FARUZAN,
      characters.GOROU,
      characters.YANFEI,
    ],
  },
  {
    number: 40,
    name: "3.3",
    items: [
      characters.AYATO,
      characters.RAIDEN,
      characters.KUJOU_SARA,
      characters.SAYU,
      characters.ROSARIA,
    ],
  },
  {
    number: 41,
    name: "3.4",
    items: [
      characters.XIAO,
      characters.ALHAITHAM,
      characters.YAOYAO,
      characters.YUN_JIN,
      characters.XINYAN,
    ],
  },
  {
    number: 42,
    name: "3.4",
    items: [
      characters.YELAN,
      characters.HU_TAO,
      characters.BEIDOU,
      characters.NINGGUANG,
      characters.XINGQIU,
    ],
  },
  {
    number: 43,
    name: "3.5",
    items: [
      characters.CYNO,
      characters.BENNETT,
      characters.COLLEI,
      characters.BARBARA,
    ],
  },
  {
    number: 44,
    name: "3.5",
    items: [
      characters.AYAKA,
      characters.SHENHE,
      characters.MIKA,
      characters.SUCROSE,
      characters.DIONA,
    ],
  },
  {
    number: 45,
    name: "3.6",
    items: [
      characters.NILOU,
      characters.NAHIDA,
      characters.KUKI_SHINOBU,
      characters.DORI,
      characters.LAYLA,
    ],
  },
  {
    number: 46,
    name: "3.6",
    items: [
      characters.BAIZHU,
      characters.GANYU,
      characters.KAVEH,
      characters.CANDACE,
      characters.FISCHL,
    ],
  },
  {
    number: 47,
    name: "3.7",
    items: [
      characters.YAE_MIKO,
      characters.YOIMIYA,
      characters.KIRARA,
      characters.YUN_JIN,
      characters.CHONGYUN,
    ],
  },
  {
    number: 48,
    name: "3.7",
    items: [
      characters.KAZUHA,
      characters.ALHAITHAM,
      characters.YAOYAO,
      characters.XIANGLING,
      characters.HEIZOU,
    ],
  },
  {
    number: 49,
    name: "3.8",
    items: [
      characters.EULA,
      characters.KLEE,
      characters.RAZOR,
      characters.THOMA,
      characters.MIKA,
    ],
  },
  {
    number: 50,
    name: "3.8",
    items: [
      characters.KOKOMI,
      characters.SCARAMOUCHE,
      characters.ROSARIA,
      characters.YANFEI,
      characters.FARUZAN,
    ],
  },
  {
    number: 51,
    name: "4.0",
    items: [
      characters.LYNEY,
      characters.YELAN,
      characters.BARBARA,
      characters.BENNETT,
      characters.LYNETTE,
    ],
  },
  {
    number: 52,
    name: "4.0",
    items: [
      characters.ZHONGLI,
      characters.CHILDE,
      characters.NOELLE,
      characters.SAYU,
      characters.FREMINET,
    ],
  },
  {
    number: 53,
    name: "4.1",
    items: [
      characters.NEUVILLETTE,
      characters.HU_TAO,
      characters.FISCHL,
      characters.XINGQIU,
      characters.DIONA,
    ],
  },
  {
    number: 54,
    name: "4.1",
    items: [
      characters.WRIOTHESLEY,
      characters.VENTI,
      characters.CHONGYUN,
      characters.THOMA,
      characters.DORI,
    ],
  },
  {
    number: 55,
    name: "4.2",
    items: [
      characters.FURINA,
      characters.BAIZHU,
      characters.BEIDOU,
      characters.COLLEI,
      characters.CHARLOTTE,
    ],
  },
  {
    number: 56,
    name: "4.2",
    items: [
      characters.CYNO,
      characters.AYATO,
      characters.XIANGLING,
      characters.KUKI_SHINOBU,
      characters.KIRARA,
    ],
  },
  {
    number: 57,
    name: "4.3",
    items: [
      characters.NAVIA,
      characters.AYAKA,
      characters.SUCROSE,
      characters.ROSARIA,
      characters.CANDACE,
    ],
  },
  {
    number: 58,
    name: "4.3",
    items: [
      characters.RAIDEN,
      characters.YOIMIYA,
      characters.BENNETT,
      characters.KUJOU_SARA,
      characters.CHEVREUSE,
    ],
  },
  {
    number: 59,
    name: "4.4",
    items: [
      characters.XIANYUN,
      characters.NAHIDA,
      characters.NOELLE,
      characters.FARUZAN,
      characters.GAMING,
    ],
  },
  {
    number: 60,
    name: "4.4",
    items: [
      characters.XIAO,
      characters.YAE_MIKO,
      characters.NINGGUANG,
      characters.XINYAN,
      characters.YAOYAO,
    ],
  },
  {
    number: 61,
    name: "4.5",
    items: [
      characters.CHIORI,
      characters.ITTO,
      characters.GOROU,
      characters.YUN_JIN,
      characters.DORI,
    ],
  },
  {
    number: 62,
    name: "4.5",
    items: [
      characters.NEUVILLETTE,
      characters.KAZUHA,
      characters.BARBARA,
      characters.XINGQIU,
      characters.YANFEI,
    ],
  },
  {
    number: 63,
    name: "4.6",
    items: [
      characters.ARLECCHINO,
      characters.LYNEY,
      characters.XIANGLING,
      characters.LYNETTE,
      characters.FREMINET,
    ],
  },
  {
    number: 64,
    name: "4.6",
    items: [
      characters.SCARAMOUCHE,
      characters.BAIZHU,
      characters.BEIDOU,
      characters.LAYLA,
      characters.FARUZAN,
    ],
  },
  {
    number: 65,
    name: "4.7",
    items: [
      characters.ALHAITHAM,
      characters.CLORINDE,
      characters.BENNETT,
      characters.THOMA,
      characters.SETHOS,
    ],
  },
  {
    number: 66,
    name: "4.7",
    items: [
      characters.FURINA,
      characters.SIGEWINNE,
      characters.GAMING,
      characters.ROSARIA,
      characters.NOELLE,
    ],
  },
];
